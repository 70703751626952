import { Viewport } from "next";
import "../globals.css";
import StoreProvider from "../StoreProvider";
import { Toaster } from "react-hot-toast";

export const viewport: Viewport = {
  width: "device-width",
  initialScale: 1,
};

export default function RootLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <StoreProvider screenId={1}>
      <Toaster />
      {children}
    </StoreProvider>
  );
}
