import React, { FC } from "react";

const GlobalFooter: FC = () => {
  return (
    <div className="home">
      <div className="global-footer">
        <div className="container">
          <div className="links">
            <img className="column" src="./images/column.png" />
            <div className="rectangle"></div>
            <div className="column-2">
              <div className="heading-3">About</div>
              <div className="global-footer-links">
                <div className="link">
                  <div className="link-2">Founders Note</div>
                </div>
                <div className="link">
                  <div className="link-2">Our Company</div>
                </div>
                <div className="link">
                  <div className="link-2">Our History</div>
                </div>
              </div>
            </div>
            <div className="column-2">
              <div className="heading-3">Contact</div>
              <div className="global-footer-links">
                <div className="link">
                  <div className="link-2">Link Eleven</div>
                </div>
              </div>
            </div>
          </div>
          <div className="credits">
            <div className="divider"></div>
            <div className="div-2">
              <div className="credits-2">
                <p className="text-4">
                  © 2023 Relume. All rights reserved Micromojo ApS CVR-nr. DK
                  44139170
                </p>
                <div className="global-footer-links-2">
                  <div className="link-3">Privacy Policy</div>
                  <div className="link-3">Terms of Service</div>
                  <div className="link-3">Cookies Settings</div>
                </div>
              </div>
            </div>
          </div>
          <img className="layer-2" src="./images/layer-1.svg" />
        </div>
        <img className="image" src="./images/image-12.png" />
      </div>
    </div>
  );
};

export default GlobalFooter;
