import { useAppDispatch } from "@/lib/hooks";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setModalData } from "./TermsModalSlice";
import { paymentMethod } from "@/app/api/endpoints/registration";
import { incremented } from "./screensSlice";
import { paymentMethodState } from "@/lib/types/types";

const initialState: paymentMethodState = {
  acceptEmails: false,
  clientSecret: "",
  paymentId: 0,
  selectedMethod: "creditCard",
};

const paymentMethodSlice = createSlice({
  name: "paymentMethod",
  initialState,
  reducers: {
    setEmailsCheckBoxValue: (
      state,
      action: PayloadAction<{ name: "acceptEmails"; value: boolean }>
    ) => {
      const { name, value } = action.payload;
      state[name] = value;
    },
    setClientSecret: (state, action: PayloadAction<string>) => {
      state.clientSecret = action.payload;
    },
    setPaymentId: (state, action: PayloadAction<number>) => {
      state.paymentId = action.payload;
    },
    setSelectedMethod: (
      state,
      action: PayloadAction<{ name: any; value: any }> // Updated the payload type
    ) => {
      const { name, value } = action.payload;

      state.selectedMethod = value;
    },
  },
});

export const {
  setEmailsCheckBoxValue,
  setClientSecret,
  setPaymentId,
  setSelectedMethod,
} = paymentMethodSlice.actions;
export default paymentMethodSlice.reducer;
export const useHandleTermsModal = () => {
  const dispatch = useAppDispatch();
  const handleTermsModal = () => {
    dispatch(
      setModalData({
        open: true,
      })
    );
  };
  return handleTermsModal;
};
export const useHandlePaymentMethod = () => {
  const dispatch = useAppDispatch();
  const handlePaymentMethod = () => {
    dispatch(handlePayment());
  };
  return handlePaymentMethod;
};

export const handlePayment = () => async (dispatch: any, getState: any) => {
  const { accessToken } = getState().registrationForm;
  paymentMethod(accessToken).then((data) => {
    dispatch(setClientSecret(data?.data?.clientSecret));
    dispatch(setPaymentId(data?.data?.id));
  });
};
