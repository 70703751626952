import { screensState } from "@/lib/types/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: screensState = {
  screenId: 0,
  title: "",
  description: "",
  showDots: false,
  image: "",
  showLogo: true,
  showHeader: true,
};

const screensSlice = createSlice({
  name: "screenId",
  initialState,
  reducers: {
    incremented: (state) => {
      state.screenId += 1;
    },
    decremented: (state) => {
      state.screenId -= 1;
    },
    initializeScreen: (state, action: PayloadAction<number>) => {
      state.screenId = action.payload;
    },

    headerData: (state, action) => {
      state.screenId = action.payload;
      switch (action.payload) {
        case 1:
          state.title = "Purchase Lovegame";
          state.description = `Phone number`;
          state.showDots = true;

          break;
        case 2:
          state.title = "Purchase Lovegame";
          state.description = `Verification code`;
          state.showDots = true;

          break;
        case 3:
          state.title = "Purchase Lovegame";
          state.description = `Your name and e-mail`;
          state.showDots = true;

          break;
        case 4:
          state.title = "Purchase Lovegame";
          state.description = `Payment method`;
          state.showDots = true;
          state.showLogo = true;
          state.showHeader = true;

          break;
        case 5:
          state.title = "Purchase Lovegame";
          state.description = "Payment method";
          state.showDots = true;
          state.showLogo = true;
          state.showHeader = true;
          break;
        case 6:
          state.title = "Congratulations";
          state.description = "";
          state.showDots = true;
          state.showLogo = true;
          state.showHeader = true;
          break;
        case 8:
          state.title = "Great news!";
          state.description = "";
          state.showDots = false;
          state.showLogo = true;
          state.showHeader = true;
          break;
        default:
          state.title = "";
          state.description = "";
          state.showDots = false;
          state.image = "";
          state.showLogo = true;
          state.showHeader = true;

          break;
      }
    },
  },
});

export const { incremented, decremented, initializeScreen, headerData } =
  screensSlice.actions;
export default screensSlice.reducer;
