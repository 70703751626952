import React, { FC } from "react";

const GlobalHeader: FC = () => {
  return (
     
          <div className="menu">
              <img className="img" src="./images/vector.svg" />
              <div className="text-wrapper-5">micromojo</div>
              {/* <img className="vector-2" src="./images/vector-25.svg" /> */}
              {/* <img className="vector-3" src="./images/vector-26.svg" /> */}
          </div>
     
  );
};
export default GlobalHeader;
