import { useAppDispatch, useAppSelector } from "@/lib/hooks";

class ApiClient {
    private baseUrl: string;

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    // public async get(accessToken: string, url: string, queryData?: {}) {
    //     const queryParams = new URLSearchParams(queryData);
    //     const response = await fetch(`${this.baseUrl}${url}?${queryParams}`, {
    //         method: "GET",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer  ${accessToken}`,
    //         },
    //     });

    //     if (!response.ok) {
    //         throw new Error("Something went wrong");
    //     }

    //     const headers: any = {};
    //     response.headers.forEach((value, key) => {
    //         headers[key] = value;
    //     });

    //     const data = await response.json();

    //     if (!response.ok) {
    //         throw new Error(data.message || "Something went wrong");
    //     }
    //     return { data, headers };
    // }

    // public async delete(accessToken: string, url: string, queryData?: {}) {
    //     const response = await fetch(`${this.baseUrl}${url}`, {
    //         method: "DELETE",
    //         headers: {
    //             "Content-Type": "application/json",
    //             Authorization: `Bearer ${accessToken}`,
    //         },
    //     });

    //     const headers: any = {};
    //     response.headers.forEach((value, key) => {
    //         headers[key] = value;
    //     });

    //     if (!response.ok) {
    //         throw new Error(response.statusText || "Something went wrong");
    //     }

    //     return { response, headers };
    // }
    public async post(url: string, queryData?: {}, accessToken?: string) {
        const response = await fetch(`${this.baseUrl}${url}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(queryData),
        });

        const headers: any = {};
        response.headers.forEach((value, key) => {
            headers[key] = value;
        });

        const data = await response.json();

        return { data, response, headers };
    }
}

export const apiClient = new ApiClient(
    "https://test-dev.micromojo.app"
);
